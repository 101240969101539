var textWrapper = document.querySelector(".headline .headline-text");
textWrapper.innerHTML = textWrapper.textContent.replace(
  /\S/g,
  "<span class='headline-text slogan'>$&</span>"
);

anime
  .timeline({ loop: false })
  .add({
    targets: ".headline .headline-text",
    translateX: [40, 0],
    translateZ: 0,
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 1200,
    delay: (el, i) => 500 + 50 * i
  })
  .add({
    targets: ".headline .headline-underline",
    scaleX: [0, 1],
    opacity: [0.5, 1],
    easing: "easeInOutExpo",
    duration: 500
  })
  .add({
    targets: ".sub .sub-text",
    translateY: 250,
    translateZ: 0,
    opacity: [0, 1],
    easing: "easeInOutExpo",
    // duration: 750,
    delay: 700
  });
//   .add({
//     targets: ".ml6",
//     opacity: 0,
//     duration: 1000,
//     easing: "easeOutExpo",
//     delay: 1000
//   });
